body a {
  text-decoration: none;
  background-color: "text.main";
}

body {
  height: 100vh;
  font-family: "Arial";
  color: white;
  background-color: "background.main";
}

.webgl {
  position: fixed;
  top: 0;
  left: 0;
  outline: none;
}

.container {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100vh;
  display: grid;
  place-content: center;
}

.content {
  display: flex;
  gap: 5em;
  width: 100%;
  padding-top: 3em;
  position: relative;
}

.content:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border-bottom: 1px solid white;
  transform: scaleX(1);
}

h1 {
  font-size: 4rem;
  width: 50vw;
  line-height: 97%;
  text-align: right;
}

section {
  height: 100vh;
  color: white;
}

.webgl {
  position: fixed;
  top: 0;
  left: 0;
  outline: none;
}
#clip {
  background: var(
    --linear-1,
    linear-gradient(135deg, #001277 0%, #148e7e 76.87%, #19a97f 100%)
  );

  background-attachment: fixed;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;

  font-size: 28vw;
  font-weight: bold;
  text-align: center;
}
.ql-toolbar {
  background-color: rgba(255, 255, 255, 0.8) !important;
}

.ql-editor h2 {
  font-size: 2.5rem;
  font-weight: normal;
}
.ql-editor h3 {
  font-size: 2.5rem;
  font-weight: normal;
}
.ql-editor h4 {
  font-size: 2.25rem;
  font-weight: normal;
}
.ql-editor h5 {
  font-size: 2rem;
  font-weight: normal;
}
.ql-editor h6 {
  font-size: 1.75rem;
  font-weight: normal;
}
.ql-editor {
  font-size: 1.3rem;
}

input::-webkit-calendar-picker-indicator {
  filter: invert(1) brightness(50%) sepia(100%) saturate(10000%)
    hue-rotate(180deg);
  cursor: pointer;
}

.chart-container {
  padding-right: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.chart-box {
  width: 75%;
  margin: 0 auto;
}

.card-container {
  padding-right: 30px;
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
}

@media screen and (max-width: 1280px) {
  .chart-container {
    padding-right: 0;
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .card-container {
    padding-right: 0;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
}
@media screen and (max-width: 840px) {
  .card-container {
    padding-right: 0;
    grid-template-columns: 1fr;
    gap: 20px;
  }
}
